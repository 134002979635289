/*
ExtendedDate can be used similar to JavaScript's Date, but with a few extras:
- create a Date in one of these ways easily:
  new ExtendedDate('01.01.2023')
  new ExtendedDate('2023-01-01')
  new ExtendedDate(new Date())
- get a formatted Date easily:
  const mydate = new ExtendedDate(whatever)
  console.log(mydate.dmy) // 01.01.2023
  console.log(mydate.ymd) // 2023-01-01
*/
export class ExtendedDate extends Date {
  constructor (date, ...rest) {
    if (typeof date === 'string') {
      const matches = date.match(/^([0-9]{2})\.([0-9]{2})\.([0-9]{4})$/)
      if (matches) {
        date = `${matches[3]}-${matches[2]}-${matches[1]}`
        // Convert date to UTC timezone
        date = new Date(date)
        // * 60000 to turn minutes to milliseconds
        const userTimezoneOffset = date.getTimezoneOffset() * 60000
        date = new Date(date.getTime() + userTimezoneOffset)
      }
      if (date === '') {
        date = null
      }
    }
    super(date, ...rest)
  }

  static today () {
    const date = new ExtendedDate(new Date())
    date.setHours(0, 0, 0, 0)
    return date
  }

  getRealMonth () {
    return this.getMonth() + 1
  }

  getRealWeekday () {
    return this.getDay() || 7
  }

  getMonthDays () {
    const date = new ExtendedDate(this.getFullYear(), this.getMonth() + 1, 0)
    return date.getDate()
  }

  getFirstDayOfMonth () {
    const date = this.clone()
    date.setDate(1)
    return date
  }

  getAria (lang) {
    const langNew = (lang === 'de') ? 'de-DE' : 'en-GB'
    return this.toLocaleDateString(langNew, { day: 'numeric', month: 'long', year: 'numeric', weekday: 'long' })
  }

  addDays (days) {
    const date = this.clone()
    date.setDate(date.getDate() + days)
    return date
  }

  addMonths (months) {
    const date = this.clone()
    date.setMonth(date.getMonth() + months)
    return date
  }

  equals (date) {
    const other = new ExtendedDate(date)
    return this.ymd === other?.ymd
  }

  static equalsStatic (date1, date2) {
    const date = new ExtendedDate(date1)
    return date.equals(date2)
  }

  static countNightsStatic (from, to) {
    const fromDate = new ExtendedDate(from)
    const toDate = new ExtendedDate(to)
    return Math.round((toDate.getTime() - fromDate.getTime()) / 1000 / 24 / 60 / 60)
  }

  isAfter (date, includeDate = false) {
    return (this.ymd > date?.ymd) || (includeDate && this.equals(date))
  }

  isBefore (date, includeDate = false) {
    return (this.ymd < date?.ymd) || (includeDate && this.equals(date))
  }

  isBetween (from, to, includeFromAndTo = true) {
    return this.isAfter(from, includeFromAndTo) && this.isBefore(to, includeFromAndTo)
  }

  isPast () {
    return this.constructor.today().isAfter(this)
  }

  isValid () {
    return !isNaN(this.getTime()) && this.getTime() !== 0
  }

  clone () {
    return new ExtendedDate(this)
  }

  #to_iso () {
    if (!this.isValid()) {
      return ''
    }

    return (new Date(this.getTime() - (this.getTimezoneOffset() * 60000))).toISOString()
  }

  get dmy () {
    return this.#to_iso(this).replace(/([0-9]{4})-([0-9]{2})-([0-9]{2}).*/, '$3.$2.$1')
  }

  get ymd () {
    return this.#to_iso(this).substr(0, 10)
  }
}
